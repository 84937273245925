"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = mixinStringifySafe;
var _jsonStringifySafe = _interopRequireDefault(require("json-stringify-safe"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function mixinStringifySafe(data) {
  return Object.defineProperty(data, 'stringifySafe', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: function () {
      let serializer = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let indent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return (0, _jsonStringifySafe.default)(this, serializer, indent, (key, value) => {
        return {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: value.sys.id,
            circular: true
          }
        };
      });
    }
  });
}